<template>
  <div class="grid-3col middle-big">
    <!-- <button @click="testFunc">testing</button> -->
    <div class="profile-left-card">
      <div class="d-flex justify-content-center">
        <!-- <img :src="imageURL" id="avatarPrev"> -->
        <div class="d-flex flex-column align-items-center">
          <h5>Profile pic</h5>
          <img
            id="avatarPrev"
            :src="imgWithTransforms({ path: user.avatar, type: ['avatar', 'large'] })"
          >
          <ImageUpload
            :cropping="true"
            :base-folder="'user'"
            :folder-id="user.id"
            :upload-preset="'mlfx_avatar_unsigned'"
          />
        </div>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="link"
        class="mt-2 red-text"
      >
        Delete account
      </b-button>
      <!-- media -->
    </div>
    <!--/ media -->

    <!-- form -->
    <div>
      <h3 class="mb-2">
        You're on the {{ user.plan }} plan.
      </h3>
      <label class="primary" for="username">Username</label>
      <form class="user-form">
        <input
          id="username"
          v-model="user.name"
          class="primary"
          type="text"
          name="username"
        >
        <label class="primary" for="email">Email (contact us to change)</label>
        <input
          id="userEmail"
          v-model="$auth.user.email"
          class="primary"
          type="text"
          name="email"
          disabled
        >
        <label class="primary" for="countries">Country</label>
        <select
          id="countries"
          v-model="user.country"
          class="primary"
          name="country"
        >
          <option
            v-for="country in countries"
            :key="country"
            :value="country"
            :selected="country"
          >
            {{ country }}
          </option>
        </select>
        <label
          class="button primary"
          value="Submit"
          @click="updateUserProfile(user)"
        >Update</label>
      </form>
    </div>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import ImageUpload from '@/views/components/appImageUpload.vue'
import useUserSingle from '@/composables/users/useUserSingle'
import Ripple from 'vue-ripple-directive'
import countries from '@/data/countries.json'
import useBroadcast from '@/composables/broadcasts/useBroadcasts'
import useCloudinary from '@/composables/useCloudinary'

export default {
  components: {
    BButton,
    ImageUpload,
  },
  setup() {
    const { broadcastById, getBroadcastById } = useBroadcast()
    const { imgWithTransforms } = useCloudinary()

    const {
      updateUserProfile,
      user,
    } = useUserSingle()

    getBroadcastById(2)

    return {
      broadcastById,
      user,
      updateUserProfile,
      imgWithTransforms,
    }
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      image: null,
      imageURL: null,
      coordinates: null,
      newFile: null,
      countries,
    }
  },
}
</script>

<style lang="scss">
.profile-left-card {
  padding: 2rem;
  background-color: white;
  border: 1px solid #e4e4e4;
  border-radius: 1rem;
  box-shadow: 0 4px 24px 0 rgb(43 43 43 / 10%);
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.user-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  #countries {
    margin-bottom: 2rem;
  }
}

// vue-avatar
#avatarPrev {
  margin-top: 1rem;
  margin-bottom: 2rem;
  border: 2px solid rgb(78, 78, 78);
  border-radius: 100rem;
  height: 120px;
  width: 120px;
}

#mainCropper {
  display: none;
  margin-top: 2rem;
}

.avatar-buttons {
  display: flex;
  flex-direction: column;
}

.hidden {
  display: none;
}
// avatar

.profile-gen-form-wrap {
  display: flex;
  flex: wrap;
}

#hiName {
  font-size: 1.6rem;
  line-height: 1.6rem;
}
.user-pic {
  border: 1px solid hsl(0, 0%, 25%);
}
.upgrade-link {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1.8rem;
  padding-left: 0.5rem;
}
.social-circle {
  padding: 0.75rem 1rem 0.75rem 1rem;
  font-size: 20px;
  border-radius: 100px;
  border: 1px solid rgb(218, 218, 218);
  background-color: hsl(0, 0%, 91%);
  margin-right: 0.6rem;
}

.red-text {
  color: rgb(185, 28, 28);
}
</style>
