<template>
  <div>
    <appButton
      :button-type="'primary'"
      @click.native="showUploadWidget"
    >
      upload
    </appButton>
  </div>
</template>

<script>
import appButton from '@/views/components/appButton.vue'
import { computed } from '@vue/composition-api'
import useUserSingle from '@/composables/users/useUserSingle'

export default {
  components: {
    appButton,
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    cropping: {
      type: Boolean,
      default: false,
    },
    uploadPreset: {
      type: String,
      default: '',
    },
    baseFolder: {
      type: String,
      default: 'users',
    },
    folderId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { user, updateUserProfile } = useUserSingle()
    const folderString = computed(() => `${props.baseFolder}/${props.folderId}`)

    return {
      folderString,
      user,
      updateUserProfile,
    }
  },
  methods: {
    showUploadWidget() {
      // for signed uploads
      // const generateSignature = (callback, paramsToSign) => {
      //   this.$axios.request({
      //     url: 'https://www.my-domain.com/my_generate_signature',
      //     method: 'GET',
      //     dataType: 'text',
      //     data: { data: paramsToSign },
      //     complete() { console.log('complete') },
      //     success(signature, textStatus, xhr) { callback(signature) },
      //     error(xhr, status, error) { console.log(xhr, status, error) },
      //   })
      // }
      window.cloudinary.openUploadWidget({
        cloudName: 'mlfx',
        uploadPreset: this.uploadPreset,
        sources: ['local', 'camera', 'facebook', 'instagram'],
        // googleApiKey: '<image_search_google_api_key>',
        // showAdvancedOptions: true,
        cropping: this.cropping, // if true, single image
        multiple: this.multiple,
        maxFiles: 10,
        defaultSource: 'local',
        folder: this.folderString,
        allowedFormats: ['images'],
        maxImageFileSize: 2000000, // restrict file size to less than 2MB
        maxImageWidth: 2000,
        styles: {
          palette: {
            window: '#FFFFFF',
            windowBorder: '#90A0B3',
            tabIcon: '#0078FF',
            menuIcons: '#5A616A',
            textDark: '#000000',
            textLight: '#FFFFFF',
            link: '#0078FF',
            action: '#FF620C',
            inactiveTabIcon: '#0E2F5A',
            error: '#F44235',
            inProgress: '#0078FF',
            complete: '#20B832',
            sourceBg: '#E4EBF1',
          },
          fonts: { default: { active: true } },
        },
      }, (err, data) => {
        if (!err) {
          let url
          let transforms
          let path
          switch (data.event) {
            case 'success':
              if (this.baseFolder === 'user') {
                path = data.info.path
                this.user.avatar = path
                this.updateUserProfile()
              } else if (this.baseFolder === 'articles') {
                path = data.info.path
                transforms = 'c_scale,w_900/f_auto'
                url = `https://res.cloudinary.com/mlfx/image/upload/${transforms}/${path}`
                this.article.images.push({ path, url })
              }
              break
            default:
              this.$log('info', 'Upload Widget event - ', data.info)
          }
        }
      })
    },
  },
}
</script>

<style>
</style>
