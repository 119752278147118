<template>
  <button
    :class="'button ' + buttonType"
  >
    <slot>button</slot>
  </button>
</template>

<script>
export default {
  props: {
    buttonType: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss">
</style>
